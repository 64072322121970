import { gql } from '@apollo/client';
export const GET_STORES = gql`
  query GetStores(
    $storeRoles: [UserRole!]
    $input: StoresFilterInput
    $withFilters: Boolean = false
  ) {
    stores(storeRoles: $storeRoles, input: $input) {
      entities {
        id
        lastName
        firstName
        role
        slug
        hasAma
        hasMerch
        hasStreams
        hasProducts
        hasExperiences
        hasMediaPost
        storeDetails {
          id
          storeName
          storeId
          storeTitle
          avatarURL
        }
        sports {
          id
          name
        }
      }
      filters @include(if: $withFilters) {
        sports {
          id
          name
        }
        hashtags {
          id
          name
          count
        }
      }
      total
      offset
      limit
    }
  }
`;

export const GET_STORES_NAMES_LIST = gql`
  query GetStoresNamesList(
    $storeRoles: [UserRole!]
    $input: StoresFilterInput
  ) {
    stores(storeRoles: $storeRoles, input: $input) {
      entities {
        id
        firstName
        hashtags {
          id
          name
        }
        lastName
        storeDetails {
          id
          storeName
          storeTitle
          avatarURL
        }
        slug
      }
      total
      offset
      limit
    }
  }
`;

export const SEARCH_STORES = gql`
  query SearchStores($storeRoles: [UserRole!], $input: StoresFilterInput) {
    stores(storeRoles: $storeRoles, input: $input) {
      entities {
        id
        lastName
        firstName
        role
        storeDetails {
          id
          storeName
          storeTitle
          avatarURL
        }
        slug
        sports {
          id
          name
        }
      }
      total
      offset
      limit
    }
  }
`;

export const STORES_BY_HASHTAG = gql`
  query StoresByHashtag($input: StoresByHashtagInput!) {
    storesByHashtag(input: $input) {
      entities {
        id
        lastName
        firstName
        role
        storeDetails {
          id
          storeName
          storeTitle
          avatarURL
        }
        slug
        sports {
          id
          name
        }
      }
      total
      offset
      limit
    }
  }
`;

export const GET_STORE_BY_SLUG = gql`
  query GetStoreBySlug($input: StoreGetInput!) {
    store(input: $input) {
      id
      slug
      lastName
      firstName
      role
      amas {
        id
        type
        price
        videoURL
        videoThumbnailURL
        status
      }
      status
      nextStream {
        id
        slug
        description
        name
        scheduleDate
        timeZone {
          tzCode
          offset
        }
        repeatsEveryEnum
        isPurchased
        streamStatus
        imageURL
        isFree
        price
        store {
          id
          slug
          storeDetails {
            id
            storeName
            storeTitle
            description
            bio
            avatarURL
            coverURL
            socialMediaLink
            gender
            athleticLevel
            careerStatus
          }
        }
      }
      hashtags {
        id
        name
      }
      hasMerch
      hasAma
      hasMediaPost
      hasProducts
      hasMemorabilia
      hasPureProducts
      hasExperiences
      hasStreams
      hasActiveStreams
      hasArticle
      storeDetails {
        storeName
        avatarURL
        description
        bio
      }
    }
  }
`;

export const GET_LEAGUES_LIST = gql`
  query GetLeaguesList {
    getLeaguesList {
      id
      name
      sport
    }
  }
`;

export const GET_TEAMS_LIST = gql`
  query GetTeamsList {
    getTeamsList {
      id
      league
      name
      sport
    }
  }
`;

export const GET_COLLEGES_LIST = gql`
  query GetCollegesList {
    getCollegesList {
      id
      name
    }
  }
`;

export const GET_MENTIONS_STORES = gql`
  query GetMentionsStores($storeRoles: [UserRole!], $input: StoresFilterInput) {
    stores(storeRoles: $storeRoles, input: $input) {
      entities {
        id
        storeDetails {
          id
          storeName
          storeId
          storeTitle
          avatarURL
        }
      }
      total
      offset
      limit
    }
  }
`;
